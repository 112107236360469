<template lang="pug">
div
  v-container(fluid)
    div(v-if="$route.params.idCampaign == 5000")
      .text-right.d-lg-none
        v-icon(@click="goback()") mdi-close
      v-container.accent2.my-lg-10.container-importadores-inner
        div(style="background: #0d8d8b")
          b(
            style="font-size: 28px; background: #0d8d8b; width: 100%; color: white; padding: 0px 10px"
          ) PRECIO: DESDE <span style="background: yellow; padding: 0px 10px; color: black; font-weight: 800">S/ 2.750</span> FACTURADOS
        v-row(no-gutters)
          v-col(cols="12", lg="6")
            v-card
              .d-flex.justify-center
                img(
                  width="100%",
                  src="https://point.qreport.site/files/concentradoroxigeno.png"
                )
          v-col(cols="12", lg="3")
            v-card.cardCenter(elevation="0")
              video(controls, loop, autoplay, muted)
                source(
                  src="@/assets/video/piccargoconcentradores.mp4",
                  type="video/mp4"
                )

          v-col(cols="12", lg="3")
            v-card.cardCenter(elevation="0")
              a.btnOpen(
                block,
                large,
                href="https://calculadoradefletes.com/assets/img/fichaConcentradores.pdf",
                target="_blank"
              ) Ver Ficha Técnica Olive 10s 1 Flujo
              //-a.btnOpen(block large href="https://calculadoradefletes.com/assets/img/fichaolivetwo.png" target="_blank") Ver Ficha Técnica Olive 2 Flujo
              a.btnOpen(
                block,
                large,
                href="https://calculadoradefletes.com/assets/img/fichatecnicaoxygen.pdf",
                target="_blank"
              ) Ver Ficha Técnica Oxygen Power
              a.btnContactar(href="https://wa.link/5bcgll", target="_blank") Contactar
                img(width="100%", src="@/assets/campaign/wasap.svg")

      .d-flex.d-lg-none(style="height: 100px")
  v-container(fluid)
    div(v-if="$route.params.idCampaign == 5001")
      .text-right.d-lg-none
        v-icon(@click="goback()") mdi-close
      v-container.accent2.my-lg-10.container-importadores-inner
        v-row(no-gutters)
          v-col(cols="12", lg="6")
            v-card
              .d-flex.justify-center
                img(
                  width="100%",
                  src="https://point.qreport.site/files/casacasdisponibles.png"
                )
          v-col(cols="12", lg="3")
            v-card.cardCenter(elevation="0")
              video(controls, loop, autoplay, muted, width="100%")
                source(src="@/assets/video/casacas.mp4", type="video/mp4")

          v-col(cols="12", lg="3")
            v-card.cardCenter(elevation="0")
              a.btnContactar(href="https://wa.link/jghdqp", target="_blank") Contactar
                img(width="100%", src="@/assets/campaign/wasap.svg")
          v-row
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/1.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/2.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/3.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/4.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/5.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/6.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/7.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/8.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/9.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/10.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/11.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/12.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/13.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/14.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/15.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/16.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/17.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/18.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/19.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/20.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/21.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/22.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/23.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/24.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/25.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/26.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/27.jpg"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/28.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/29.jpg"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/casacas/30.jpg"
              )

  v-container(fluid)
    div(v-if="$route.params.idCampaign == 5003")
      .text-right.d-lg-none
        v-icon(@click="goback()") mdi-close
      v-container.accent2.my-lg-10.container-importadores-inner
        //-div(style="background: #0d8d8b")
          b(
            style="font-size: 28px; background: #0d8d8b; width: 100%; color: white; padding: 0px 10px"
          ) PRECIO: DESDE <span style="background: yellow; padding: 0px 10px; color: black; font-weight: 800">S/ 60</span> FACTURADOS
        v-row(no-gutters)
          v-col(cols="12", lg="6")
            v-card
              .d-flex.justify-center
                img(
                  width="100%",
                  src="https://point.qreport.site/files/pilar/portadapilar.png"
                )
          //-v-col(cols="12", lg="6")
            v-card.cardCenter(elevation="0")
              video(controls, loop, autoplay, muted, width="100%")
                source(
                  src="@/assets/video/videopañaleras.mp4",
                  type="video/mp4"
                )

          v-col(cols="12", lg="3").text-center
            v-card.cardCenter(elevation="0")
              a.btnContactar(href="https://wa.link/89zln5", target="_blank") Contactar
                img(width="100%", src="@/assets/campaign/wasap.svg")
              a(href="https://drive.google.com/drive/folders/1tKPLO6BIOV_2kPjLJE1d7UM2wH43EzqG", target="_blank" style="font-size: 38px; font-weight: 700").text-center VER STOCK DE PRODUCTOS AQUÍ
          //-v-row
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/pañalera1.png"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/pañalera2.png"
              )
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/pañalera3.png"
              ) 
            v-col(cols="6", lg="3")
              img(
                width="100%",
                src="https://point.qreport.site/files/pañalera4.png"
              )
          //-v-row
            v-col(cols="6" lg="3")
              img(width="100%" src="https://point.qreport.site/files/casacas/1.jpg")
            v-col(cols="6" lg="3")
              img(width="100%" src="https://point.qreport.site/files/casacas/2.jpg")


      .d-flex.d-lg-none(style="height: 100px")
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Importadores",
  data: () => ({}),
  methods: {
    ...mapActions(["getCampaignsStockAPI", "getImportadoresAPI"]),
    goback() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["getImportadores", "getCampaignsStock"]),
    campaignStock() {
      return this.getCampaignsStock.filter((campaign) => {
        return campaign.id === Number(this.$route.params.idCampaign);
      })[0];
    },
  },
  mounted() {
    window.scroll(0, 0);
    this.getCampaignsStockAPI();
    this.getImportadoresAPI(this.$route.params.idCampaign);
  },
};
</script>
<style scoped>
.container-importadores-inner {
  width: 100%;
}
.cardCenter {
  padding: 15px;
}
.btnOpen {
  display: block;
  width: 100%;
  background: #e2e2e2;
  text-align: center;
  color: #3a3a3a;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 10px;
}

.btnContactar {
  display: block;
  width: 100%;
  background: #249134;
  text-align: center;
  color: white;
  padding: 10px;
  font-size: 34px;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 10px;
  border-radius: 15px;
}
@media only screen and (min-width: 1264px) {
  .container-importadores-inner {
    width: 70%;
  }
}
</style>
